.cartItems thead th {
  text-transform: uppercase;
}

.cartItems th {
  padding-bottom: 1.8rem;
  opacity: 0.85;
  border-bottom: 0.1rem solid rgba(18, 18, 18, 0.08);
}

th {
  display: table-cell;
  vertical-align: inherit;
}

.columnProperties {
  font-size: 10px;
  letter-spacing: 0.13rem;
  line-height: calc(1 + 0.2 / 1);
}

@media screen and (min-width: 768px) {
  .cartItems td {
    vertical-align: top;
    padding-top: 2rem;
  }

  .cartItems > td + td {
    padding-left: 4rem;
  }
}

@media screen and (max-width: 767px) {
  .cartItems thead tr {
    display: flex;
    justify-content: space-between;
    border-bottom: 0.1rem solid rgba(18, 18, 18, 0.2);
    margin-bottom: 4rem;
  }

  .cartItems thead {
    display: block;
    width: 100%;
  }
}
